<template>
	<div>
		<template>
      <v-data-table
        :headers="tables.exercises.headers"
				:items="exercises.filter(e => (tables.exercises.filters.exerciseCategoryId==-1
					|| e.Exercise_Categories.filter(eC => eC.id==tables.exercises.filters.exerciseCategoryId).length!=0)
					&&(tables.exercises.filters.recommendedAgeGroupId==-1
						|| e.Exercise_MeasurementUnits.filter(EMU => EMU.ExerciseMU_Age__Groups.filter(EMUAG => EMUAG.AgeGroupId==tables.exercises.filters.recommendedAgeGroupId).length>0).length>0)
						&&(tables.exercises.filters.exerciseType=='all'?true:(tables.exercises.filters.exerciseType=='test'?e.test==true:(tables.exercises.filters.exerciseType=='competition'?e.competition==true:e.training==true))))"

        :search="tables.exercises.search"
				sort-by="name"
      >
	      <template v-slot:top>
					<v-row>
						<v-col cols="12" :sm="$store.getters.userRole>=roles.nationalCoordinator ? 5 : 6">
			        <v-text-field
			          v-model="tables.exercises.search"
			          label="Keresés"
			          prepend-inner-icon="fa-search"
			          class="mx-4"
								rounded
								outlined
			        />
						</v-col>
						<v-col cols="12" sm="2">
							<v-select
								:items="[{value: 'training', name: 'Foglalkozás'},{value: 'competition', name: 'Verseny'},{value: 'test', name: 'Teszt'},{value: 'all', name: 'Mindegyik'},]"
								item-text="name"
								item-value="value"
								label="Gyakorlattípus"
								v-model="tables.exercises.filters.exerciseType"
								rounded
								outlined
							/>
						</v-col>
						<v-col cols="12" sm="2">
							<v-select
								:items="[{id: -1, name: 'Mindegyik'}].concat(exerciseCategories)"
								item-text="name"
								item-value="id"
								label="Mozgásterület"
								v-model="tables.exercises.filters.exerciseCategoryId"
								rounded
								outlined
								prepend-inner-icon="fa-layer-group"
							/>
						</v-col>
						<v-col cols="12" sm="2">
							<v-select
								:items="[{id: -1, name: 'Mindegyik'}].concat(ageGroups.map(aG => {
									return {
										id: aG.id,
										name: `${aG.shortname} - ${aG.name}`
									}
									}))"
								item-text="name"
								item-value="id"
								label="Korcsoport"
								v-model="tables.exercises.filters.recommendedAgeGroupId"
								rounded
								outlined
								prepend-inner-icon="fa-child"
							/>
						</v-col>
						<v-col cols="12" sm="1" v-if="$store.getters.userRole>=roles.nationalCoordinator">
							<v-tooltip top>
								Hozzáadás
								<template v-slot:activator="{on, attrs}">
									<v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialog.show=true"><v-icon>fa-plus</v-icon></v-btn>
								</template>
							</v-tooltip>
						</v-col>
					</v-row>
	      </template>

				<template v-slot:item.competition="{item}">
					<v-icon :color="item.competition? 'success' : 'red accent-4'">{{item.competition? "fa-check" : "fa-times"}}</v-icon>
				</template>
				<template v-slot:item.test="{item}">
					<v-icon :color="item.test? 'success' : 'red accent-4'">{{item.test? "fa-check" : "fa-times"}}</v-icon>
				</template>
				<template v-slot:item.training="{item}">
					<v-icon :color="item.training? 'success' : 'red accent-4'">{{item.training? "fa-check" : "fa-times"}}</v-icon>
				</template>
				<template v-slot:item.description="{item}">
					<span v-html="item.description.substring(0,200)+'...'"/>
				</template>
				<template v-slot:item.ageGroups="{item}">
					<span v-for="eMU in item.Exercise_MeasurementUnits" :key="eMU.MeasurementUnitId">
						<v-chip-group>
							<v-tooltip top  v-for="aG in eMU.ExerciseMU_Age__Groups">
								{{aG.repetition}}x{{aG.quantity}} {{measurementUnits.find(mU => mU.id==eMU.MeasurementUnitId).unit}}, becsült idő: {{aG.time}}
								<template v-slot:activator="{on, attrs}">
									<v-chip v-on="on" v-bind="attrs">{{ageGroups.find(_aG => _aG.id==aG.AgeGroupId).shortname}} - {{ageGroups.find(_aG => _aG.id==aG.AgeGroupId).name}}</v-chip>
								</template>
							</v-tooltip>
						</v-chip-group>
					</span>
	      </template>
				<template v-slot:item.exerciseCategories="{item}">
					<v-tooltip top v-for="CAT in item.Exercise_Categories" :key="CAT.id">
						{{exerciseCategories.find(cat => CAT.id==cat.id).description}}
						<template v-slot:activator="{on, attrs}">
							<v-chip v-on="on" v-bind="attrs">{{exerciseCategories.find(cat => CAT.id==cat.id).name}}</v-chip>
						</template>
					</v-tooltip>
	      </template>

				<template v-slot:item.view="{item}">
					<v-btn icon :href="$router.resolve({name: 'exercise', params: {id: item.id}}).href" target="_blank"><v-icon>fa-eye</v-icon></v-btn>
				</template>

      </v-data-table>
		  <v-row justify="center">
		    <v-dialog
		      v-model="dialog.show"
		      persistent
		      max-width="600px"
		    >

		      <v-card>
		        <v-card-title>
		          <span class="headline">Új gyakorlat</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-text-field
									prepend-inner-icon="fa-dumbbell"
									label="Gyakorlat"
									v-model="dialog.exercise.name"
									rounded
									outlined
								/>
								Leírás
								<trumbowyg :config="{lang: 'hu'}" v-model="dialog.exercise.description"/>
								<v-checkbox
									label="Foglalkozásgyakorlat"
									v-model="dialog.exercise.training"
								/>
								<v-checkbox
									label="Versenygyakorlat"
									v-model="dialog.exercise.competition"
								/>
								<v-checkbox
									label="Tesztgyakorlat"
									v-model="dialog.exercise.test"
								/>

		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialog.show = false"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="create()"
								v-if="dialog.exercise.name&&dialog.exercise.description"
		          >
		            Hozzáadás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
	    </v-dialog>
	  </v-row>

	</template>
	</div>
</template>

<script>
	import router from '../router';
	export default {
		data: function(){
			return {
        exercises: [],
        measurementUnits: [],
        exerciseCategories: [],
				ageGroups: [],
        tables: {
          exercises: {
            search: '',
						filters: {
							exerciseCategoryId: -1,
							recommendedAgeGroupId: -1,
							exerciseType: 'training' //training/test/competition/all
						},
            headers: [
              {
                text: "Gyakorlat",
                align: "center",
                sortable: true,
                filterable: true,
                value: "name"
              },
              /*{
                text: "Leírás",
                align: "center",
                sortable: false,
                filterable: true,
                value: "description"
              },*/
							{
                text: "Foglalkozás",
                align: "center",
                sortable: false,
                filterable: true,
                value: "training"
              },
              {
                text: "Verseny",
                align: "center",
                sortable: false,
                filterable: true,
                value: "competition"
              },
							{
                text: "Teszt",
                align: "center",
                sortable: false,
                filterable: true,
                value: "test"
              },
              {
                text: "Mozgásterület",
                align: "center",
                sortable: false,
                filterable: false,
                value: "exerciseCategories"
              },
							{
								text: "Korcsoportok",
								align: "center",
								sortable: false,
								filterable: false,
								value: "ageGroups"
							},
							{
								text: "Megtekintés",
								align: "center",
								sortable: false,
								filterable: false,
								value: "view"
							}
            ]
          }
        },
        dialog: {
          show: false,
					exercise: {
						name: '',
						description: '',
						competition: false,
						test: false,
						training: true
					}
        }
      }
		},
		computed: {

		},
		watch: {

		},
		methods: {
			create: function() {
				this.axios({url: "exercise", method: "POST", data: this.dialog.exercise}).then((response) => {
					if(response.data.success) {
						this.exercises.push({...this.dialog.exercise, id: response.data.data});
						this.dialog.show=false;
						this.dialog.exercise = { name: '', description: '', competition: false, test: false, training: true }
						this.$router.push({name: 'exercise', params: {id: response.data.data}})
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
					}
				})
			}
		},
		mounted(){
			this.axios({url: "exercise", method: "GET"}).then((response) => {
        if(response.data.success) {
          this.exercises=response.data.data.exercises
          this.measurementUnits = response.data.data.measurementUnits
					this.exerciseCategories=response.data.data.exerciseCategories
					this.ageGroups = response.data.data.ageGroups
        }
			})
		}
	}
</script>
